<template>
  <div class="list">
    <div class="my-list">
      <div class="my-list-title">
        <img class="icon-imgs" src="../../assets/image/show/share2.png" />
        <span>我的农场秀</span>
      </div>
      <div class="my-list-main">
        <MyNothing v-if="!myShowList.length"></MyNothing>
        <div class="items" v-for="item in myShowList" :key="item.manor_show_id">
          <div class="title one-txt-cut">{{ item.show_title }}</div>
          <img
            class="imgs"
            :src="require(`@/assets/image/show/self${item.templet_id}.jpg`)"
          />
          <div class="buttons">
            <el-button
              plain
              size="mini"
              type="primary"
              @click="handleShowEdit(item.templet_id, item.manor_show_id)"
              >编辑
            </el-button>
            <el-button
              plain
              size="mini"
              type="info"
              @click="handleSee(item.templet_id, item.manor_show_id)"
              >预览</el-button
            >
            <el-button
              plain
              size="mini"
              type="success"
              @click="handleEnable(item.templet_id, item.manor_show_id)"
              >分享
            </el-button>
            <el-button
              plain
              size="mini"
              type="danger"
              @click="handleForbidden(item.manor_show_id)"
              >删除</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="my-list my-template">
      <div class="my-list-title">
        <img class="icon-imgs" src="../../assets/image/show/share1.png" />
        <span>农场秀模板</span>
      </div>
      <div class="my-list-main">
        <MyNothing v-if="!templateList.length"></MyNothing>
        <div class="items" v-for="(item, index) in templateList" :key="index">
          <img class="imgs" :src="item.img" />
          <div class="buttons">
            <div class="title one-txt-cut">农场秀模板-{{ item.id }}</div>

            <el-button
              plain
              size="mini"
              type="primary"
              @click="userTemplate(item.id)"
              >使用模板
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFarmShowListApi } from "@/request/api.js";
import { mapState } from "vuex";

export default {
  components: {
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  props: {
    isBoxShowUpdate: {
      type: Boolean,
    },
  },
  data() {
    return {
      myShowList: [],
      templateList: [
        {
          id: 1,
          img: require("../../assets/image/show/self1.jpg"),
        },
        {
          id: 2,
          img: require("../../assets/image/show/self2.jpg"),
        },
      ],
      current_page: 1,
      total: 0,
    };
  },
  watch: {
    isBoxShowUpdate() {
      this.getFarmShowListApi();
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getFarmShowListApi();
  },
  methods: {
    async getFarmShowListApi() {
      const { code, results } = await getFarmShowListApi({
        page_num: this.current_page,
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.current_page = results.page_num;
      this.myShowList = results.data;
      this.total = results.count;
      this.$emit("showListUpdate");
    },
    handleForbidden(id) {
      this.$emit("handleForbidden", id);
    },
    handleShowEdit(templetId, show_id) {
      this.$emit("handleShowEdit", templetId, show_id);
    },
    handleSee(templetId, show_id) {
      this.$emit("handleSee", templetId, show_id);
    },
    handleEnable(templetId, show_id) {
      this.$emit("handleEnable", templetId, show_id);
    },
    userTemplate(templetId) {
      this.$emit("handleShowEdit", templetId);
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  color: #fff;
  display: flex;
  flex-direction: column;
  .my-list {
    width: 1320px;
    &:last-of-type {
      margin-bottom: 30px;
    }
    .my-list-title {
      width: 100%;
      height: 56px;
      background: #0e2f53;
      display: flex;
      align-items: center;
      padding: 0 30px;
      .icon-imgs {
        width: 28px;
      }
      span {
        font-size: 22px;
        color: #fff;
        margin-left: 12px;
      }
    }
    .my-list-main {
      width: 100%;
      height: 346px;
      background-color: #081c30;
      display: flex;
      flex-wrap: wrap;
      padding: 25px 30px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }

      .items {
        width: 277px;
        display: flex;
        flex-direction: column;
        margin-right: 42px;
        margin-bottom: 20px;
        &:nth-of-type(4n) {
          margin-right: 0;
        }
        .title {
          width: 100%;
          font-size: 14px;
          color: #fff;
          margin-bottom: 20px;
        }
        .imgs {
          width: 100%;
          height: 184px;
          border-radius: 2px;
          display: block;
        }
        /deep/.buttons {
          width: 100%;
          display: flex;
          padding-top: 29px;
          .el-button {
            width: 63px;
            height: 28px;
          }
          .el-button--primary.is-plain {
            background-color: inherit;
            border-color: rgba(62, 144, 229, 0.4);
            color: #3e90e5;
          }

          .el-button--primary.is-plain:hover,
          .el-button--primary.is-plain:focus {
            background-color: rgb(62, 144, 229);
            color: #fff;
          }

          .el-button--success.is-plain {
            background-color: inherit;
            border-color: rgba(103, 194, 58, 0.4);
            color: #67c23a;
          }

          .el-button--success.is-plain:hover,
          .el-button--success.is-plain:focus {
            background-color: rgb(103, 194, 58);
            color: #fff;
          }

          .el-button--info.is-plain {
            background-color: inherit;
            border-color: rgba(62, 184, 229, 0.4);
            color: rgb(62, 184, 229);
          }

          .el-button--info.is-plain:hover,
          .el-button--info.is-plain:focus {
            background-color: rgb(62, 184, 229);
            color: #fff;
          }

          .el-button--danger.is-plain {
            background-color: inherit;
            border-color: rgba(245, 108, 108, 0.4);
            color: #f56c6c;
          }

          .el-button--danger.is-plain:hover,
          .el-button--danger.is-plain:focus {
            background-color: rgb(245, 108, 108);
            color: #fff;
          }
        }
      }
    }
  }
  .my-template {
    /deep/.buttons {
      justify-content: space-between;
      .title {
        width: calc(100% - 103px);
      }
      .el-button {
        width: 103px !important;
      }
    }
  }
}
</style>
